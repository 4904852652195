<template>
  <div class="aside">
    <el-row class="tac">
      <el-col :span="12">
        <el-menu class="el-menu-vertical-demo" :default-active="aside_active">
          <el-menu-item
            :index="item.id+''"
            v-for="item in asideList"
            :key="item.id"
            @click="change_aside(item.id+'')"
          >
            <i class="el-icon-position"></i>
            <span slot="title">{{item.title}}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      asideList: [],
      aside_active: "0"
    };
  },
  created() {
    if (this.$store.state.aside_active != "") {
      this.aside_active = this.$store.state.aside_active;
    }
    if (this.$store.state.tab_active != "") {
      this.getAsideList(this.$store.state.tab_active);
      this.aside_active = this.$store.state.aside_active;
    }
  },
  methods: {
    async getAsideList(id) {
      this.aside_active = "0";
      let { data: res } = await this.$axios({
        method: "post",
        url: "/api/index/get_aside",
        data: {
          tab_id: id
        }
      });
      this.asideList = res.data;
      if (this.aside_active == "0") {
        if (res.data[0] != undefined) {
          this.aside_active = res.data[0].id + "";
        }
      }
      this.$store.commit("change_aside_active", this.aside_active);
    },
    change_aside(value) {
      this.aside_active = value;
      this.$store.commit("change_aside_active", value);
    }
  },
  computed: {
    tab_active() {
      return this.$store.state.tab_active;
    }
  },

  watch: {
    tab_active(newVal, oldVal) {
      this.getAsideList(newVal);
    }
  }
};
</script>

<style lang="less">
.el-col-12 {
  width: 100% !important;
}
// .el-aside {
//   height: calc(100vh - 60px);
// }
</style>
