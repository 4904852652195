<template>
  <div>
    <!-- 海报区域 -->
    <div class="img">
      <img
        src="https://xbb-1253895597.file.myqcloud.com/taoke/38d122bc3007c26e4e68f82042197ca.jpg"
        alt
        class="free-banner"
        @click="introduce"
      />
    </div>
    <!-- 搜索框区域 -->
    <div class="search-container">
      <el-button type="danger" plain style="width:100%" @click="custom_show=true">自定义转链</el-button>
    </div>
    <!-- 商品列表页 -->
    <div class="goodsList-container">
      <div class="goodsList-item" v-for="(item) in list" :key="item._id">
        <div class="goodlist-item-content">
          <div class="gic-img">
            <img v-lazy="item.img_url" mode="aspectFit" />
          </div>
          <div class="gic-info">
            <div class="gic-info-title">
              <img
                style="width: 12px;"
                src="https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=266803756,635946852&fm=26&gp=0.jpg"
              />
              {{item.sku_name}}
            </div>
            <div class="gic-info-tips">
              <div class="git-left">{{item.quota}}元优惠券</div>
              <div class="gif-right">好评数：{{item.comments}}</div>
              <div class="gif-right">好评率：{{item.comments_share}}%</div>
            </div>
            <div class="gic-info-content">
              <div>
                券后
                <span class="new">￥{{item.coupon_price}}</span>
                <span class="old">￥{{item.price}}</span>
                <span class="btn">
                  <el-button
                    type="danger"
                    plain
                    size="mini"
                    class="btn"
                    @click="change(item.sku,item.coupon_url,item.content)"
                  >转链</el-button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 登录界面 -->
    <el-dialog
      title="登录"
      :visible.sync="login_show"
      width="90%"
      :close-on-click-modal="false "
      @close="dialog_close('form')"
    >
      <el-form :model="login_form" :rules="login_rules" ref="form">
        <el-form-item label="手机号：" prop="phone">
          <el-input v-model="login_form.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码：" prop="password">
          <el-input type="password" v-model="login_form.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-link target="_blank" @click="regist_show=true;login_show=false">没有账号，点击此处进行注册</el-link>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="login_show=false">取 消</el-button>
        <el-button type="primary" @click="login('form')">登 录</el-button>
      </div>
    </el-dialog>
    <!-- 注册界面 -->
    <el-dialog
      title="注册"
      :visible.sync="regist_show"
      width="95%"
      :close-on-click-modal="false "
      @close="dialog_close('formRef')"
    >
      <el-form
        ref="formRef"
        :model="regist_form"
        label-width="75px"
        label-position="left"
        :rules="regist_rules"
        :hide-required-asterisk="true"
      >
        <el-form-item label="手机号:" prop="mobile">
          <el-input v-model="regist_form.mobile" autocomplete="off">
            <el-button v-show="showTime" slot="append" @click="send_code">发送验证码</el-button>
            <el-button v-show="!showTime" slot="append" disabled>剩余{{sendTime}}秒</el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="验证码:" prop="code">
          <el-input v-model="regist_form.code" autocomplete="off" type="number"></el-input>
        </el-form-item>
        <el-form-item label="密码:" prop="password">
          <el-input v-model="regist_form.password" autocomplete="off" type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码:" prop="password1">
          <el-input v-model="regist_form.password1" autocomplete="off" type="password"></el-input>
        </el-form-item>
        <el-form-item label="pid:" prop="pid">
          <el-input v-model="regist_form.pid" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align:center">
        <el-link target="_blank" @click="login_show=true;regist_show=false">已有账号，点击此处进行登录</el-link>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="regist_show=false">取 消</el-button>
        <el-button type="primary" @click="register('formRef')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 自定义转链 -->
    <el-dialog title="自定义转链" :visible.sync="custom_show" width="90%" :close-on-click-modal="false ">
      <div>
        <el-input type="textarea" autosize v-model="custom_text"></el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="turn" type="primary">转补贴</el-button>
        <el-button @click="custom_show=false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import qs from "qs";
import Clipboard from "clipboard";
export default {
  data() {
    var checkMobile = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    var checkPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("确认密码不能为空"));
      } else {
        if (value != this.regist_form.password) {
          return callback(new Error("两次输入的密码不一致"));
        } else {
          callback();
        }
      }
    };
    return {
      login_show: false,
      regist_show: false,
      login_form: {
        phone: "",
        password: ""
      },
      custom_text: "",
      custom_show: false,
      pid: "",
      showTime: true /* 布尔值，通过v-show控制显示‘获取按钮’还是‘倒计时’ */,
      sendTime: "" /* 倒计时 计数器 */,
      regist_form: {
        password: "",
        password1: "",
        mobile: "",
        code: "",
        pid: ""
      },
      list: [],
      regist_rules: {
        code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
        pid: [{ required: true, message: "pid不能为空", trigger: "blur" }],
        mobile: [{ required: true, validator: checkMobile, trigger: "blur" }],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          { min: 6, message: "长度最少6个字符", trigger: "blur" }
        ],
        password1: [
          { required: true, trigger: "blur", validator: checkPassword }
        ]
      },
      login_rules: {
        phone: [{ required: true, validator: checkMobile, trigger: "blur" }],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          { min: 6, message: "长度最少6个字符", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    // 获取商品
    async getList() {
      let { data: res } = await this.$axios({
        method: "get",
        url: "https://mini.mkstone.club/go/api/wx/v1/goods"
      });
      if (res.Code === 1) {
        this.list = res.Data;
      }
    },
    // 发送验证码
    async send_code() {
      this.$refs.formRef.validateField("mobile", async err => {
        if (err) {
          console.log("校验失败");
        } else {
          //倒计时
          const TIME_COUNT = 60; //  更改倒计时时间
          if (!this.timer) {
            this.sendTime = TIME_COUNT;
            this.showTime = false;
            this.timer = setInterval(() => {
              if (this.sendTime > 0 && this.sendTime <= TIME_COUNT) {
                this.sendTime--;
              } else {
                this.showTime = true;
                clearInterval(this.timer); // 清除定时器
                this.timer = null;
              }
            }, 1000);
          }
          //发请求
          let { data: res } = await this.$axios({
            method: "post",
            url: "/api/index/send_code",
            data: {
              mobile: this.regist_form.mobile
            }
          });
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "success"
            });
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    dialog_close(form) {
      this.$refs[form].resetFields();
    },
    // 注册
    register(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          let { data: res } = await this.$axios({
            method: "post",
            url: "/api/goods/regist",
            data: {
              form: this.regist_form
            }
          });
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "success"
            });
            this.regist_form.mobile = "";
            this.regist_form.password = "";
            this.regist_form.password1 = "";
            this.regist_form.code = "";
            this.regist_form.pid = "";
            this.regist_show = false;
          } else {
            this.$message.error(res.msg);
          }
        } else {
          console.log(valid);
          return false;
        }
      });
    },
    // 转补贴
    turn() {
      var re1 = /(https?):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
      let flag = false;
      var arr = this.custom_text.match(re1);
      let str = this.custom_text;
      arr.map(async url => {
        if (this.pid == "") {
          this.login_show = true;
          return false;
        }
        let { data: res1 } = await this.$axios({
          method: "post",
          url: "https://xpb.jshangpin.com/api/v1/mk/jd/wx",
          data: qs.stringify({
            pid: this.pid,
            jd_url: url
          })
        });
        if (res1.Code != 1) {
          this.$message({
            message: "转链失败",
            type: "error"
          });
          return false;
        }
        str = str.replace(url, res1.Data);
        console.log(str);
        this.copy(str);
      });
      // async url => {
      //   console.log("url")
      //   if (this.pid == "") {
      //     this.login_show = true;
      //     return url;
      //   }
      //   let { data: res1 } = await this.$axios({
      //     method: "post",
      //     url: "https://xpb.jshangpin.com/api/v1/mk/jd/wx",
      //     data: qs.stringify({
      //       pid: this.pid,
      //       jd_url: url
      //     })
      //   });
      //   if (res1.Code != 1) {
      //     this.$message({
      //       message: "转链失败",
      //       type: "error"
      //     });
      //     return url;
      //   }
      //   return res1.Data;
      // }
    },
    // 转链
    async change(sku, coupon_url, content) {
      if (this.pid == "") {
        this.login_show = true;
        return false;
      }
      let { data: res } = await this.$axios({
        method: "post",
        url: "https://mini.mkstone.club/go/api/taoke/v1/getPromotionMore",
        data: qs.stringify({
          material_id: `https://item.jd.com/${sku}.html?rid=10902`,
          union_id: 1003550365,
          coupon_url: coupon_url
        })
      });
      if (res.Code != 1) {
        this.$message({
          message: "转链失败",
          type: "error"
        });
        return false;
      }
      let jd_url = res.Data;
      let { data: res1 } = await this.$axios({
        method: "post",
        url: "https://xpb.jshangpin.com/api/v1/mk/jd/wx",
        data: qs.stringify({
          pid: this.pid,
          jd_url: jd_url
        })
      });
      if (res1.Code != 1) {
        this.$message({
          message: "转链失败",
          type: "error"
        });
        return false;
      }
      var Url2 = content + "\r" + res1.Data;
      this.copy(Url2);
    },
    // 复制
    copy(Url2) {
      if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
        var oInput = document.createElement("textarea");
        oInput.value = Url2;
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象
        oInput.setSelectionRange(0, oInput.value.length); //兼容ios
        document.execCommand("Copy"); // 执行浏览器复制命令
        oInput.className = "oInput";
        oInput.style.display = "none";
      } else {
        var oInput = document.createElement("textarea");
        oInput.value = Url2;
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象
        document.execCommand("Copy"); // 执行浏览器复制命令
        oInput.className = "oInput";
        oInput.style.display = "none";
      }
      this.$message({
        message: "转链成功，已经复制了",
        type: "success"
      });
    },
    // 活动说明
    introduce() {
      this.$alert({
        title: "活动说明",
        message: `<div>
        <div>1.不要将本网页发到视频号官方群</div>
        <div>2.此网页转后的链接可以让你跟单到联盟视频号补贴</div>
        <div>3.此网页转后的链接，是拿不到佣金的，能跟到京粉订单，但是佣金为0，只吃视频号补贴，介意勿用！</div>
        <div>4.以下操作都要做： （创建微信小商店的PID，小商店里绑定PID）</div>
        <div><a href='https://newbuz.360buyimg.com/video/4.5/jdvideo.html?autoplay=false&fuScrnEnabled=true&playbackRateEnabled=true&fileid=433927791165059073&appid=1251412368&sw=1280&sh=720&clarity=hd' target='_blank'>5.视频号带货入门（创建视频号、绑定小商店、绑定京东联盟pid），必看！！！</a></div>
        <div>
        【开完店后一定一定一定要】到这里提报参赛信息
        <a href='https://jdcps.jinshuju.com/f/Pr6Lbx' target='_blank'>https://jdcps.jinshuju.com/f/Pr6Lbx</a>
        必须填，不填无法发放活动奖励佣金，联盟ID和pid必须填报正确，切记！其他填错或后续修改不要紧
        </div>
        <div>6.补贴奖励订单从 京粉横幅，视频号大赛里进入，点击战队的查看详情，进去就看到了。</div>
        </div>`,
        dangerouslyUseHTMLString: true
      });
    },
    // 登录
    login(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          let { data: res } = await this.$axios({
            method: "post",
            url: "/api/goods/login",
            data: {
              form: this.login_form
            }
          });
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "success"
            });
            this.pid = res.data;
            console.log(this.pid);
            this.login_form.phone = "";
            this.login_form.password = "";
            this.login_show = false;
          } else {
            this.$message.error(res.msg);
          }
        } else {
          console.log(valid);
          return false;
        }
      });
    }
  },
  created() {
    this.getList();
    document.title = "视频号商品库";
  }
};
</script>

<style lang="less">
@media screen and (max-width: 750px) {
  .el-message-box {
    width: 90% !important;
  }
}
</style>


<style lang="less" scoped>
// 海报区域
.img {
  .free-banner {
    width: 100%;
    display: block;
  }
}

// 搜索框
.search-container {
  display: flex;
  width: 90%;
  margin: 20px auto 10px auto;
  .search {
    flex: 1;
    border: 1px solid #ccc;
    font-size: 16px;
    border-radius: 5px 0 0 5px;
    padding: 5px;
    input {
      border: 0;
      width: 100%;
      background-color: transparent;
      color: #fff;
      line-height: 23px;
    }
  }
  .text {
    width: 71px;
    background-color: #ff6b01;
    border-radius: 0 5px 5px 0;
    color: #ffffff;
    font-weight: bold;
    line-height: 35px;
    font-size: 16px;
    text-align: center;
  }
}
// 商品列表页
.goodsList-container {
  width: 95%;
  margin: 10px auto;

  img {
    max-width: 100%;
  }

  .goodsList-item {
    border-radius: 10px;
  }

  .goodlist-item-content {
    padding-top: 15px;
    display: flex;
    align-items: center;

    .gic-img {
      width: 125px;

      image {
        width: 125px;
        height: 125px;
        border-radius: 7.5px;
      }
    }

    .gic-info {
      width: 250px;

      .gic-info-title {
        margin-left: 10px;
        font-size: 15px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .gic-info-tips {
        display: flex;
        font-size: 10px;
        margin-top: 5px;
        align-items: center;

        .git-left {
          margin-left: 10px;
          padding: 2.5px;
          border-radius: 5px;
          color: #ac7750;
          border: 1px solid #ac7750;
        }

        .gif-right {
          margin-left: 7.5px;
          color: #8f8f8f;
        }
      }

      .gic-info-content {
        margin-left: 10px;
        margin-top: 15px;
        font-weight: 500;

        .new {
          font-size: 21px;
          color: #dc6537;
          font-weight: bold;
          margin: 0 5px;
        }

        .old {
          font-size: 12px;
          text-decoration: line-through;
          color: #8f8f8f;
        }

        .btn {
          position: absolute;
          right: 10px;
        }
      }
    }
  }

  .my_sell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    .my_sell_left {
      font-weight: bold;
      font-size: 14px;

      text {
        margin-left: 7.5px;
        color: #5e58f2;
      }
    }

    .my_sell_more {
      color: #b6b6b6;
      font-size: 13px;
    }
  }
}
</style>