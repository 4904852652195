import { render, staticRenderFns } from "./turnChain.vue?vue&type=template&id=4ba5db0d&scoped=true&"
import script from "./turnChain.vue?vue&type=script&lang=js&"
export * from "./turnChain.vue?vue&type=script&lang=js&"
import style0 from "./turnChain.vue?vue&type=style&index=0&id=4ba5db0d&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ba5db0d",
  null
  
)

export default component.exports