<template>
  <div class="header">
    <div class="logo" @click="goHome">
      <img src="../assets/logo.jpg" alt="">
    </div>
    <el-tabs type="card" v-model="tab_active" style="flex:1;margin-right:60px">
      <el-tab-pane v-for="item in typeList" :key="item.id" :name="item.id+''">
        <span slot="label">
          <img class="icon" :src="item.image" />
          {{item.name}}
        </span>
      </el-tab-pane>
    </el-tabs>
    <div>
      <el-button-group v-if="$store.state.user==''||$store.state.user==null">
        <el-button plain @click="login">登录</el-button>
        <el-button plain @click="regist">注册</el-button>
      </el-button-group>
      <div class="username" v-else>
        <el-button type="primary" @click="$store.commit('change_myInfo_show')">点击查看个人信息</el-button>
        <!-- <span>用户名：{{$store.state.user.username}}</span>
        <span style="margin-left:15px">剩余金币：{{$store.state.user.money}}</span> -->
        <!-- <span style="margin-left:15px">appkey：{{$store.state.user.appkey}}</span> -->
        <!-- <span style="margin-left:15px">
          <el-button size="mini">充值</el-button>
        </span> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      typeList: [],
      tab_active: 0
    };
  },
  created() {
    this.getType();
    if (this.$store.state.tab_active != "") {
      this.tab_active = this.$store.state.tab_active;
    }
  },
  watch: {
    tab_active(newVal, oldVal) {
      this.$store.commit("change_tab_active", newVal);
    }
  },
  methods: {
    // 跳转首页
    goHome(){
      window.location='http://gjr.mkstone.club/'
    },
    // 登录
    login() {
      this.$store.commit("change_login_show");
    },
    // 注册
    regist() {
      this.$store.commit("change_regist_show");
    },
    // 获取分类
    async getType() {
      let { data: res } = await this.$axios({
        url: "/api/index/getType"
      });
      this.typeList = res.data;
      if (this.tab_active == 0) {
        this.tab_active = res.data[0].id + "";
        console.log(this.tab_active);
      }
      this.$store.commit("change_tab_active", this.tab_active);
    }
  }
};
</script>

<style lang="less" scoped>
.logo{
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-self: center;
  height: 45px;
  line-height: 45px;
  margin-right: 20px;
  width: 100px;
  img{
    width: 100%;
  }
}
.header {
  display: flex;
  // justify-content: space-between;
}
.icon {
  width: 20px;
  vertical-align: middle;
  padding-right: 5px;
}
.username {
  height: 40px;
  line-height: 40px;
}
</style>