<template>
  <!-- 注册界面 -->
  <el-dialog
    title="注册"
    :visible.sync="regist_show"
    width="50%"
    :close-on-click-modal="false "
    @close="dialog_close()"
  >
    <el-form ref="formRef" :model="form" label-width="100px" label-position="left" :rules="rules">
      <el-form-item label="用户名：" prop="username">
        <el-input v-model="form.username" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="密码：" prop="password">
        <el-input v-model="form.password" autocomplete="off" type="password"></el-input>
      </el-form-item>
      <el-form-item label="确认密码：" prop="password1">
        <el-input v-model="form.password1" autocomplete="off" type="password"></el-input>
      </el-form-item>
      <el-form-item label="手机号：" prop="mobile">
        <el-input v-model="form.mobile" autocomplete="off">
          <el-button v-show="showTime" slot="append" @click="send_code">发送验证码</el-button>
          <el-button v-show="!showTime" slot="append" disabled>剩余{{sendTime}}秒</el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="验证码：" prop="code">
        <el-input v-model="form.code" autocomplete="off" type="number"></el-input>
      </el-form-item>
      <el-form-item label="电子邮箱：" prop="email">
        <el-input v-model="form.email" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="qq号：" prop="qq">
        <el-input v-model="form.qq" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div style="text-align:center">
      <el-link target="_blank" @click="login">已有账号，点击此处进行登录</el-link>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="$store.commit('change_regist_show')">取 消</el-button>
      <el-button type="primary" @click="register('formRef')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    var checkMobile = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    var checkPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("确认密码不能为空"));
      } else {
        if (value != this.form.password) {
          return callback(new Error("两次输入的密码不一致"));
        } else {
          callback();
        }
      }
    };
    var checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!value) {
        return callback(new Error("邮箱不能为空"));
      } else {
        if (mailReg.test(value)) {
          callback();
        } else {
          callback(new Error("请输入正确的邮箱格式"));
        }
      }
    };
    return {
      showTime: true /* 布尔值，通过v-show控制显示‘获取按钮’还是‘倒计时’ */,
      sendTime: "" /* 倒计时 计数器 */,
      timer: null,
      form: {
        username: "",
        password: "",
        password1: "",
        mobile: "",
        email: "",
        qq: "",
        code: ""
      },
      rules: {
        username: [
          { required: true, message: "用户名不能为空", trigger: "blur" }
        ],
        qq: [{ required: true, message: "qq不能为空", trigger: "blur" }],
        code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
        mobile: [{ required: true, validator: checkMobile, trigger: "blur" }],
        email: [{ required: true, validator: checkEmail, trigger: "blur" }],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          { min: 6, message: "长度最少6个字符", trigger: "blur" }
        ],
        password1: [
          { required: true, trigger: "blur", validator: checkPassword }
        ]
      }
    };
  },
  computed: {
    regist_show: {
      get() {
        return this.$store.state.regist_show;
      },

      set(v) {
        this.$store.commit("change_regist_show");
      }
    }
  },
  methods: {
    login() {
      this.$store.commit("change_regist_show");
      this.$store.commit("change_login_show");
    },
    dialog_close() {
      this.$refs["formRef"].resetFields();
    },
    // 发送验证码
    async send_code() {
      this.$refs.formRef.validateField("mobile", async err => {
        if (err) {
          console.log("校验失败");
        } else {
          //倒计时
          const TIME_COUNT = 60; //  更改倒计时时间
          if (!this.timer) {
            this.sendTime = TIME_COUNT;
            this.showTime = false;
            this.timer = setInterval(() => {
              if (this.sendTime > 0 && this.sendTime <= TIME_COUNT) {
                this.sendTime--;
              } else {
                this.showTime = true;
                clearInterval(this.timer); // 清除定时器
                this.timer = null;
              }
            }, 1000);
          }
          //发请求
          let { data: res } = await this.$axios({
            method: "post",
            url: "/api/index/send_code",
            data: {
              mobile: this.form.mobile
            }
          });
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "success"
            });
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    // 注册
    register(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          let { data: res } = await this.$axios({
            method: "post",
            url: "/api/index/regist",
            data: {
              form: this.form
            }
          });
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "success"
            });
            this.form.username = "";
            this.form.password = "";
            this.form.password1 = "";
            this.form.mobile = "";
            this.form.email = "";
            this.form.qq = "";
            this.form.code = "";
            this.$store.commit("change_regist_show");
          } else {
            this.$message.error(res.msg);
          }
        } else {
          console.log(valid);
          return false;
        }
      });
    }
  }
};
</script>

<style lang="less">
.el-form--inline {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
</style>