<template>
  <div class="videoList">
    <!-- 公告内容 -->
    <el-card class="box-card">
      <div class="notice">
        <el-alert style="background-color:#e1f0ff" :closable="false">
          <template slot="title">
            <div v-html="notice"></div>
          </template>
        </el-alert>
      </div>
      <div class="list">
        <div class="list_item" v-for="item in album_list" :key="item.id" @click="goDetail(item.id)">
          <div class="list_item_title">{{item.title}}</div>
          <div class="list_item_tips">
            <div class="lit_item">
              <i class="iconfont icon-rili"></i>
              {{item.createtime|timestamp}}
            </div>
            <div class="lit_item">
              <i class="iconfont icon-wenjianjia"></i>视频教程
            </div>
            <div class="lit_item">
              <i class="iconfont icon-tongji"></i>
              共{{item.videolist_count}}集
            </div>
          </div>
          <div class="list_item_img" v-if="item.image!=''">
            <img :src="item.image" alt />
          </div>
          <div class="list_item_context" v-html="item.content"></div>
          <div class="list_item_more">
            <i class="iconfont icon-wenjian"></i>查看全文
          </div>
          <el-divider></el-divider>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      album_list: [],
      notice: ""
    };
  },
  created() {
    this.get_video_album();
    this.get_notice();
  },
  methods: {
    // 获取视频专辑列表
    async get_video_album() {
      let { data: res } = await this.$axios({
        url: "/api/video/get_video_album"
      });
      if (res.code === 1) {
        this.album_list = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    // 跳转到详情页
    goDetail(id) {
      this.$router.push({ name: "videoDetail", params: { id: id } });
    },
    // 获取公告
    async get_notice() {
      let { data: res } = await this.$axios({
        method: "post",
        url: "/api/video/get_notice"
      });
      if (res.code == 1) {
        this.notice = res.data.content;
      } else {
        this.$message.error(res.msg);
      }
    }
  }
};
</script>

<style lang="less">
.videoList {
  .el-card__body {
    padding: 10px !important;
  }
  .el-divider--horizontal {
    margin-top: 15px;
  }
}
</style>

<style lang="less" scoped>
.videoList {
  width: 1200px;
  margin: 0 auto;
  .list {
    .list_item {
      cursor: pointer;
      &:first-child {
        margin-top: 28px;
      }
      margin-top: 22.4px;
      padding: 0 8px;
      .list_item_title {
        font-size: 1.4rem;
        color: #1e90ff;
      }
      .list_item_tips {
        font-size: 14px;
        display: flex;
        color: #cccccc;
        padding: 8px 0;
        i {
          padding-right: 2px;
        }
        .lit_item {
          &:first-child {
            padding: 0;
          }
          padding-left: 25px;
        }
      }
      .list_item_img {
        width: 100%;
        img {
          border-radius: 5px;
          border: 1px solid #ccc;
          max-width: 100%;
        }
      }
      .list_item_context {
        margin-top: 5px;
        font-size: 16px;
        color: #999;
        line-height: 1.6rem;
      }
      .list_item_more {
        i {
          padding-right: 5px;
        }
        text-align: right;
        margin-top: 20px;
        color: #1890ff;
        font-size: 14px;
      }
    }
  }
}
</style>