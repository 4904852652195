<template>
  <div class="videoDetail">
    <el-card class="box-card">
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>视频教程</el-breadcrumb-item>
          <el-breadcrumb-item>{{detail.title}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!-- 标题区域 -->
      <div class="title_container">
        <div class="title_context">{{detail.title}}</div>
        <div class="title_tips">
          <div class="title_tips_item">
            <i class="iconfont icon-rili"></i>
            {{detail.createtime|timestamp}}
          </div>
          <div class="title_tips_item">
            <i class="iconfont icon-wenjianjia"></i>视频教程
          </div>
          <div class="title_tips_item">
            <i class="iconfont icon-tongji"></i>
            共{{detail.videolist_count}}集
          </div>
        </div>
      </div>
      <!-- 总结区域 -->
      <div class="summary">
        <div v-html="detail.content"></div>
      </div>
      <!-- 视频列表区域 -->
      <div class="video_list" v-if="detail!=null">
        <div class="video_list_item" v-for="item in detail.videolist" :key="item.id">
          <div class="vli_title">{{item.title}}</div>
          <div class="vli_video">
            <iframe
              :src="item.url"
              scrolling="no"
              border="0"
              frameborder="no"
              framespacing="0"
              allowfullscreen="true"
              width="100%"
            ></iframe>
          </div>
          <div class="vli_summary" v-html="item.content"></div>
        </div>
      </div>
    </el-card>
    <el-card class="timeline_card">
      <div class="timeline_card_title">文章目录：</div>
      <el-timeline style="margin-top:25px">
        <el-timeline-item
          v-for="(activity, index) in detail.videolist"
          :key="index"
        >{{activity.title}}</el-timeline-item>
      </el-timeline>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detail: null,
    };
  },
  created() {
    this.get_video_detail(this.$route.params.id);
  },
  methods: {
    async get_video_detail(id) {
      let { data: res } = await this.$axios({
        method: "post",
        url: "/api/video/get_video_detail",
        data: {
          album_id: id
        }
      });
      if (res.code === 1) {
        this.detail = res.data;
      }
    }
  }
};
</script>

<style lang="less">
.videoDetail {
  .el-breadcrumb__inner {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
  .el-card__body {
    padding: 10px !important;
  }
}
</style>

<style lang="less" scoped>
.videoDetail {
  width: 1200px;
  display: flex;
  margin: 0 auto;
  .box-card {
    width: 840px;
    .breadcrumb {
      background-color: #e1f0ff;
      padding: 20px;
    }
    .title_container {
      margin: 0 auto;
      padding: 8px 0;
      .title_context {
        color: rgba(0, 0, 0, 0.65);
        font-size: 25.6px;
        text-align: center;
        padding: 16px;
      }
      .title_tips {
        padding: 13px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #ccc;
        .title_tips_item {
          padding: 0 15px;
        }
      }
    }
    .summary {
      padding: 0 20px;
      font-size: 16.8px;
      color: #777;
      line-height: 30.4px;
    }
    .video_list {
      padding: 0 20px;
      .video_list_item {
        .vli_title {
          border-bottom: 1px solid #d9dada;
          margin-bottom: 32px;
          margin-top: 32px;
          color: #333;
          font-size: 20.8px;
          font-weight: bold;
          padding-bottom: 16px;
        }
        .vli_summary {
          margin-top: 5px;
          font-size: 14.7px;
          color: #777;
          line-height: 26.6px;
        }
      }
    }
    iframe {
      height: 34rem;
      border: 1px solid #ccc;
      border-radius: 8px;
    }
  }
  .timeline_card {
    margin-left: 20px;
    width: 300px;
    height: 500px;
    .timeline_card_title{
      font-size: 25.6px;
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
</style>