<template>
  <div class="video">
    <el-header>
      <div class="header">
        <img @click="goHome" src="../assets/logo.jpg" alt />(视频教程)
      </div>
    </el-header>
    <el-main style="background-color:#f4f5f5">
      <router-view></router-view>
    </el-main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      album_list: [],
      notice: ""
    };
  },
  created() {
    this.get_video_album();
    this.get_notice();
  },
  methods: {
    // 跳转首页
    goHome() {
      window.location = "http://gjr.mkstone.club/";
    },
    // 获取视频专辑列表
    async get_video_album() {
      let { data: res } = await this.$axios({
        url: "/api/video/get_video_album"
      });
      if (res.code === 1) {
        this.album_list = res.data;
      }
    },
    // 获取公告
    async get_notice() {
      let { data: res } = await this.$axios({
        method: "post",
        url: "/api/video/get_notice"
      });
      if (res.code == 1) {
        this.notice = res.data.content;
      }
    }
  }
};
</script>


<style lang="less" scoped>
.video {
  .header {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    line-height: 60px;
    font-size: 26px;
    border-bottom: 1px solid #eee;
    color: #1890ff;
    img {
      cursor: pointer;
      width: 140px;
    }
  }
}
</style>