<template>
  <div class="source">
    <div class="title">
      <div class="title_btn">
        <el-button type="primary">
          <a style="color:#fff" href="http://interface.mkstone.club/" target="_blank">我要对接</a>
        </el-button>
      </div>
      <div class="title_content">共京荣信息源<div style="font-size:20px">(请使用http打开此页面 https无效)</div></div>
    </div>
    <div class="source-container">
      <div class="source-item" v-for="(item,index) in data" :key="index">
        <el-card shadow="always">
          <div class="source-item-title">{{item.title}}</div>
          <div class="source-item-info">{{item.info}}</div>
          <div v-if="item.source.length==0" class="main empty">暂无信息</div>
          <div v-else class="main" :ref="`messagesContainer${index}`">
            <div class="subject" v-for="(item1,index1) in item.source" :key="index1">
              <div class="content">
                <div class="content_left">
                  <img
                    src="https://xbb-1253895597.cos.ap-nanjing.myqcloud.com/taoke/logo.png"
                    mode="widthFix"
                  />
                </div>
                <div class="content_right">
                  <div class="content_right_header">
                    <div class="title">qq消息</div>
                    <div class="time">{{item1.time}}</div>
                  </div>
                </div>
              </div>
              <div class="content_container" v-html="$options.filters.source(item1.content)"></div>
            </div>
          </div>
          <div class="url">群接口：{{item.url}}</div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      socket: [],
      data: [],
      rowNum: 3
    };
  },
  mounted() {
    // 初始化
    this.get_source();
  },
  methods: {
    async get_source() {
      let { data: res } = await this.$axios({
        method: "post",
        url: "/api/index/get_source"
      });
      if (res.code == 1) {
        let data = res.data;
        data.forEach((element, index) => {
          this.init(element, index);
          this.$set(data[index], "source", []);
        });
        this.data = data;
      }
    },
    init: function(element, index) {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        // 实例化socket
        this.socket[index] = new WebSocket(element.url);
        // 监听socket连接
        this.socket[index].onopen = this.open(element, index);
        // 监听socket错误信息
        this.socket[index].onerror = this.error;
        // 监听socket消息
        this.socket[index].onmessage = msg =>
          this.getMessage(msg, element, index);
      }
    },
    open: function(element, index) {
      console.log(`websocket-${element.title}连接成功`);
      let flag = false;
      var set = setInterval(() => {
        if (flag == true) {
          clearInterval("set");
        } else if (this.socket[index].readyState === 1) {
          flag = true;
          this.socket[index].send("123");
        }
      }, 1000);
    },
    error: function() {
      console.log(`websocket连接错误,原因:${msg.data}`);
    },
    getMessage: function(msg, element, index) {
      let timestamp = new Date();
      let y = timestamp.getFullYear();
      let m = (timestamp.getMonth() + 1).toString().padStart(2, "0");
      let d = timestamp.getDate();
      let hh = timestamp.getHours();
      let mm = timestamp.getMinutes();
      let ss = timestamp.getSeconds();
      let time = `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
      this.data[index].source.push({ time: time, content: msg.data });
      this.messagesContainerTimer = setTimeout(() => {
        console.log(this.$refs[`messagesContainer${index}`]);
        this.$refs[`messagesContainer${index}`][0].scrollTop = this.$refs[
          `messagesContainer${index}`
        ][0].scrollHeight;
        // 清理定时器
        clearTimeout(this.messagesContainerTimer);
      }, 1000);
    },
    send: function(element, index, params) {
      this.socket[index].send(params);
    },
    close: function() {
      console.log("socket已经关闭");
    }
  }
};
</script>

<style lang="less" scoped>
.source {
  .title {
    margin: 20px 0;
    position: relative;
    .title_btn {
      margin-left: 20px;
    }
    .title_content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 26px;
      font-weight: 500;
      text-align:center;
    }
  }
  .source-container {
    display: flex;
    flex-wrap: wrap;
    .source-item {
      position: relative;
      width: 32%;
      margin-top: 20px;
      margin-left: 1%;
      .url {
        width: 100%;
        z-index: 100;
        text-align: center;
        padding-top: 10px;
      }
      .empty {
        font-size: 20px;
        text-align: center;
        // padding-top: 20px;
      }
      .source-item-title {
        padding-top: 5px;
        text-align: center;
        font-size: 20px;
        color: red;
      }
      .source-item-info {
        margin-top: 20px;
        text-align: center;
        line-height: 28px;
        display: inline-block;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      //内容区域

      .main {
        height: calc(100vh - 180.4px);
        overflow-y: scroll;
        overflow-x: hidden;
      }
      .subject {
        margin-right: 8px;
        img {
          width: 100%;
        }
        &:nth-child(n + 3) {
          position: relative;
          top: -15px;
        }

        .content {
          margin: 0 5px;
          padding: 12px 12px 12px 0;
          display: flex;
          white-space: nowrap;
          align-items: center;
          position: relative;

          .content_left {
            width: 40px;
          }

          .content_right {
            color: #7a7a7a;
            width: 100%;
            font-size: 16px;

            .content_right_header {
              margin-left: 20px;
              width: 100%;
              display: flex;
              justify-content: space-between;
              flex-wrap: nowrap;
              align-items: center;
            }
          }
        }

        .content_container {
          padding: 10px;
          border-radius: 6px;
          //   width: 300px;
          position: relative;
          top: -10px;
          margin-left: 60px;
          background-color: #d7e4f4;
          font-size: 16px;
          white-space: pre-line;

          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 15px;
            width: 0;
            height: 0;
            left: -15px;
            border-left: 15px solid transparent;
            border-top: 15px solid #d7e4f4;
          }
        }
      }
    }
  }
}
</style>