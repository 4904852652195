<template>
  <div>
    <el-dialog title="我的信息" :visible.sync="myInfo_show" width="40%" @close="dialog_close" @open="get_source">
      <el-dialog :visible.sync="money_show" append-to-body title="充值信息" width="30%">
        <el-form>
          <el-form-item label="充值金额：">
            <el-input type="phone" v-model.number="num" autocomplete="off">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-form>
        <div>1元=1000金币</div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="$store.commit('change_money_show')">取 消</el-button>
          <el-button type="primary" @click="confirm">充值</el-button>
        </div>
      </el-dialog>
      <div class="item">用户名：{{$store.state.user.username}}</div>
      <div class="item">
        appkey：{{$store.state.user.appkey}}
        <el-button
          class="copy_btn"
          style="margin-left:25px"
          @click="copy"
          :data-clipboard-text="$store.state.user.appkey"
        >复制</el-button>
      </div>
      <div class="item">
        回调地址：
        <el-link type="success">（回调使用说明）</el-link>
        <el-input style="margin-top:10px" v-model="form.url" placeholder="请输入回调地址">
          <el-select
            multiple
            collapse-tags
            v-model="form.sourceId"
            slot="prepend"
            placeholder="请选择消息源"
            popper-class="option_class"
          >
            <el-option v-for="item in source" :key="item.id" :label="item.title" :value="item.id">
              <span style="float: left">{{ item.title }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">msgType：{{ item.id }}</span>
            </el-option>
          </el-select>
          <el-button slot="append" @click="test_url">{{test_btn}}</el-button>
        </el-input>
      </div>
      <div class="item">
        剩余金币：{{$store.state.user.money}}
        <el-button style="margin-left:25px" @click="recharge">充值</el-button>
      </div>
      <div class="item">电子邮箱：{{$store.state.user.email}}</div>
      <div class="item">手机号：{{$store.state.user.mobile}}</div>
      <div class="item">加入时间：{{time}}</div>
      <div style="text-align:right">
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="quit" type="danger">退出登录</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from "clipboard";
export default {
  props: ["source"],
  data() {
    return {
      num: 0,
      test_btn: "点击测试",
      form: {
        sourceId: [],
        url: ""
      }
    };
  },
  created() {
    this.get_source();
  },
  computed: {
    myInfo_show: {
      get() {
        return this.$store.state.myInfo_show;
      },

      set(v) {
        this.$store.commit("change_myInfo_show");
      }
    },
    money_show: {
      get() {
        return this.$store.state.money_show;
      },

      set(v) {
        this.$store.commit("change_money_show");
      }
    },
    time() {
      let timestamp = new Date(
        parseInt(this.$store.state.user.createtime) * 1000
      );
      let y = timestamp.getFullYear();
      let m = (timestamp.getMonth() + 1).toString().padStart(2, "0");
      let d = timestamp.getDate();
      let data = `${y}-${m}-${d}`;
      return data;
    }
  },
  watch: {
    "form.url": {
      handler: function() {
        if (this.test_btn === "测试成功" || this.test_btn === "测试失败") {
          this.test_btn = "点击测试";
        }
      }
    }
  },
  methods: {
    dialog_close(){
      this.form.sourceId = [];
      this.form.url = ""
    },
    async test_url() {
      if (this.form.url === "") {
        this.$message({
          message: "请先输入回调地址",
          type: "error"
        });
        return false;
      }
      let { data: res } = await this.$axios({
        method: "post",
        url: "/api/index/test_url",
        data: {
          url: this.form.url
        }
      });
      if (res.code === 1) {
        this.test_btn = "测试成功";
      } else {
        this.test_btn = "测试失败";
      }
    },
    async get_source() {
      let { data: res } = await this.$axios({
        method: "get",
        url: "/api/comsumer/get_source",
      });
      this.form.sourceId = res.data.source_ids.split(",").map(v=>parseInt(v));
      this.form.url = res.data.url
    },
    async save() {
      if (this.form.sourceId.length === 0) {
        this.$message({
          message: "请先选择消息源",
          type: "error"
        });
        return;
      } else if (this.form.url === "") {
        this.$message({
          message: "请先输入回调地址",
          type: "error"
        });
        return;
      } else {
        if (this.test_btn != "测试成功") {
          this.$message({
            message: "回调地址请先测试成功后保存",
            type: "error"
          });
          return;
        }
      }
      this.form.user_id = this.$store.state.user.id;
      let { data: res } = await this.$axios({
        method: "post",
        url: "/api/comsumer/save_source",
        data: this.form
      });
      this.$message({
        message: "保存成功",
        type: "success"
      });
    },
    copy() {
      var clipboard = new Clipboard(".copy_btn");
      clipboard.on("success", e => {
        this.$message({
          type: "success",
          message: "复制成功"
        });
        // 释放内存
        clipboard.destroy();
      });

      clipboard.on("error", e => {
        // 不支持复制
        this.$message.error("该浏览器不支持自动复制");

        // 释放内存
        clipboard.destroy();
      });
    },
    recharge() {
      this.$store.commit("change_money_show");
    },
    // 退出登录
    quit() {
      this.$alert
        .confirm("确定退出登录吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "退出成功!"
          });
          this.$store.commit("change_user", "");
          this.$store.commit("change_token", "");
          this.$store.commit("change_myInfo_show");
          // localStorage.setItem("store", JSON.stringify(this.$store.state));
        });
    },
    async confirm() {
      //发请求
      if (this.num <= 0) {
        return this.$message.error("充值的金额需要大于0");
      }
      let { data: res } = await this.$axios({
        method: "post",
        url: "/api/comsumer/recharge",
        data: {
          money: this.num
        }
      });
      if (res.code == 1) {
        const h = this.$createElement;
        this.$alert({
          title: "消息",
          message: h("p", null, [
            h(
              "div",
              { style: "color: red" },
              `付款金额:¥ ${Math.floor(this.num)}`
            ),
            h("div", { style: "color: red" }, "请在30分钟内完成支付"),
            h("div", { style: "color: red" }, "跳转支付宝链接支付")
          ]),
          showCancelButton: true,
          confirmButtonText: "支付完成",
          cancelButtonText: "取消"
        }).then(async action => {
          if (action == "confirm") {
            this.$store.commit("change_money_show");
            let { data: res1 } = await this.$axios({
              url: "/api/comsumer/index"
            });
            if (res1.code != 1) {
              this.$message.error(res1.msg);
            }
            let user = res1.data;
            this.$store.commit("change_user", user);
          }
        });

        let dwSafari;
        dwSafari = window.open();
        dwSafari.document.open();
        let dataObj = res.data;
        let html = dataObj.replace(/[^\u0000-\u00FF]/g, function($0) {
          return escape($0).replace(/(%u)(\w{4})/gi, "&#x$2;");
        });
        dwSafari.document.write(
          "<html><head><title></title><meta charset='utf-8'><body>" +
            dataObj +
            "</body></html>"
        );
        dwSafari.document.forms[0].submit();
        dwSafari.document.close();
      } else {
        this.$message.error(res.msg);
      }
    }
  }
};
</script>

<style lang="less">
.el-select .el-input {
  width: 130px;
}
.el-input-group__prepend {
  background-color: #fff !important;
}
.option_class {
  width: 250px;
}
</style>

<style lang="less" scoped>
.item {
  padding: 18px 0;
  margin-top: 5px;
  font-size: 16px;
}
</style>