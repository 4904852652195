import Vue from "vue";
import {
  Button,
  Container,
  Header,
  Aside,
  Main,
  Tabs,
  TabPane,
  Row,
  Col,
  Menu,
  Submenu,
  MenuItemGroup,
  MenuItem,
  BreadcrumbItem,
  Breadcrumb,
  Card,
  ButtonGroup,
  Dialog,
  Form,
  FormItem,
  Input,
  Link,
  Alert,
  Divider,
  Timeline,
  TimelineItem,
  Table,
  TableColumn,
  Pagination,
  Rate,
  Tag,
  Select,
  Option
} from "element-ui";

Vue.use(Button);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Row);
Vue.use(Col);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItemGroup);
Vue.use(MenuItem);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Card);
Vue.use(ButtonGroup);
Vue.use(Dialog);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Link);
Vue.use(Alert); 
Vue.use(Divider); 
Vue.use(Timeline); 
Vue.use(TimelineItem); 
Vue.use(Table); 
Vue.use(TableColumn); 
Vue.use(Pagination); 
Vue.use(Rate); 
Vue.use(Tag); 
Vue.use(Select); 
Vue.use(Option); 
