import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import less from "less";
import "./plugins/element.js";
import "element-ui/lib/theme-chalk/index.css";
import axios from "axios";
import { Message, MessageBox, Loading } from "element-ui";
import VueDragscroll from "vue-dragscroll";
import VueLazyLoad from 'vue-lazyload'
Vue.use(VueLazyLoad)
Vue.prototype.$message = Message;
Vue.prototype.$alert = MessageBox;
let loadingInstance = ""
axios.defaults.baseURL = "/index.php";

Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.use(less);
Vue.use(VueDragscroll);

// http请求拦截器
axios.interceptors.request.use(
  (config) => {
    loadingInstance = Loading.service({ fullscreen: true, text: "加载中……" });
    config.headers.Authorization = store.state.token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// http响应拦截器
axios.interceptors.response.use(data => {// 响应成功关闭loading
  loadingInstance.close();
  return data
}, error => {
  Message(({
    message: error?.response?.data?.msg,
    type: "error"
  }))
  loadingInstance.close();
  return Promise.reject(error)
})

// 定义时间戳过滤器
Vue.filter("timestamp", function (value) {
  let timestamp = new Date(parseInt(value) * 1000);
  let y = timestamp.getFullYear();
  let m = (timestamp.getMonth() + 1).toString().padStart(2, "0");
  let d = timestamp.getDate();
  let hh = timestamp.getHours();
  let mm = timestamp.getMinutes();
  let ss = timestamp.getSeconds();
  let data = `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
  return data;
});

Vue.filter("source", (value) => {
  var re1 = /(https?):\/\/gchat.qpic.cn[^\s]*term=2|(https?):\/\/[^\s]*.jpg/g;
  var imgArr = value.match(re1);
  var str = value.replace(re1, "");
  var emoji = /\[emoji=\w+\]/g;
  var str = str.replace(emoji, "");
  var re2 = /(https?):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
  str = str.replace(re2, function (a, b, c) {
    return `<div><a target="_blank" href="${a}">${a}</a></div>`;
  });
  if (imgArr != null) {
    imgArr.forEach((v) => {
      str += `<div><img style="margin-top:10px;max-width:100%" src="${v}"></img></div>`;
    });
  }
  return str;
});

let v = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

