<template>
  <div>
    <el-card class="database">
      <h1 style="text-align:center">京东团长数据库</h1>
      <div>
        <el-form :form="form" :inline="true" class="form-inline">
          <el-form-item label="活动名：">
            <el-input style="width:200px" v-model.trim="form.title" clearable></el-input>
          </el-form-item>
          <el-form-item label="团长名：">
            <el-input style="width:200px" v-model.trim="form.cpUnion" clearable></el-input>
          </el-form-item>
          <el-form-item label="团长等级：">
            <el-select placeholder="请选择" v-model="form.unionGrade">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="search">搜索</el-button>
          </el-form-item>
        </el-form>
        <el-table stripe :data="tableData">
          <el-table-column type="index" label="#"></el-table-column>
          <el-table-column label="活动名" prop="title" width="300"></el-table-column>
          <el-table-column label="活动id" prop="cpActId" width="150"></el-table-column>
          <el-table-column label="团长名" width="200">
            <template slot-scope="scope">
              <div>{{scope.row.cpUnionId}}</div>
            </template>
          </el-table-column>
          <el-table-column label="团长等级" width="150">
            <template slot-scope="scope">
              <el-tag type="warning">{{scope.row.unionGrade}}级团长</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="活动联系qq" width="200">
            <template slot-scope="scope">
              <div>{{scope.row.qq==0?"":scope.row.qq}}</div>
            </template>
          </el-table-column>
          <el-table-column label="团长联系方式" prop="contact" width="300"></el-table-column>
          <el-table-column label="团长总成交金额" width="180">
            <template slot-scope="scope">
              <el-rate v-model="scope.row.gmvStar" disabled></el-rate>
            </template>
          </el-table-column>
          <el-table-column label="团长总成交笔数" width="180">
            <template slot-scope="scope">
              <el-rate v-model="scope.row.orderCountStar" disabled></el-rate>
            </template>
          </el-table-column>
          <el-table-column label="团长爆品能力" width="180">
            <template slot-scope="scope">
              <el-rate v-model="scope.row.expProd" disabled></el-rate>
            </template>
          </el-table-column>
          <el-table-column label="活动时间" width="250">
            <template slot-scope="scope">
              <div>{{scope.row.startTime}} 至 {{scope.row.endTime}}</div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page="1"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 50, 100]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          style="margin-top:20px"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  created() {
    this.get_source();
  },
  data() {
    return {
      tableData: [],
      UnionBase: [],
      current_page: 1,
      page_size: 10,
      form: {
        title: "",
        cpUnion: "",
        unionGrade:""
      },
      options: [
        {
          value: "",
          label: "全部"
        },
        {
          value: "0",
          label: "0级"
        },
        {
          value: "1",
          label: "1级"
        },
        {
          value: "2",
          label: "2级"
        },
        {
          value: "3",
          label: "3级"
        }
      ]
    };
  },
  methods: {
    handleSizeChange(val) {
      this.page_size = val;
      this.get_source();
    },
    handleCurrentChange(val) {
      this.current_page = val;
      this.get_source();
    },
    // 搜索
    search() {
      this.current_page = 1;
      this.get_source();
    },
    async get_source() {
      let { data: res } = await this.$axios({
        method: "post",
        data: {
          current_page: this.current_page,
          page_size: this.page_size,
          form: this.form
        },
        url: "http://zss.mkstone.club/index.php/database/Index/index"
      });
      if (res.code == 1) {
        this.tableData = res.data;
        this.total = res.total;
      } else {
        this.$message.error(res.msg);
      }
    }
  }
};
</script>

<style lang="less">
.database {
  .el-form--inline {
    justify-content: start;
  }
}
</style>

<style lang="less" scoped>
.form-inline {
  margin-top: 20px;
}
</style>