<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  created() {
    if (localStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(localStorage.getItem("store"))
        )
      );
    }
  },
  watch: {
    token(newVal, oldVal) {
      this.getUserInfo();
    }
  },
  computed: {
    token() {
      return this.$store.state.token;
    }
  },
  methods: {
    async getUserInfo() {
      let { data: res } = await this.$axios({
        url: "/api/comsumer/index"
      });
      if (res.code != 1) {
        this.$message.error(res.msg);
        this.$store.commit("change_user", "");
        this.$store.commit("change_token", "");
      }
      let user = res.data;
      this.$store.commit("change_user", user);
    }
  }
};
</script>


<style>
</style>
