<template>
  <div>
    <div v-if="!isWeixin">
      <div style="text-align:center;margin:15px;color: #409EFF;font-size:20px">下单视频教程</div>
      <video
        style="width:100%;height:200px"
        controls
        src="https://gjr-1252058135.cos.ap-guangzhou.myqcloud.com/%E6%8F%90%E7%A4%BA%E4%B8%8B%E8%BD%BD%E4%BA%AC%E4%B8%9Cpap%E7%9A%84.mp4"
      ></video>
      <div class="link">
        <el-link type="primary" class="link_text" @click="shop">点击这里包邮1分购！</el-link>
      </div>
      <div class="image">
        <img
          src="https://gjr-1252058135.cos.ap-guangzhou.myqcloud.com/051724c4df4f6b7755c81bc3879ee6d.png"
        />
        <img
          src="https://gjr-1252058135.cos.ap-guangzhou.myqcloud.com/0b1b17536385311cea4061195fa7746.png"
        />
      </div>
    </div>
    <div v-else>
      <div class="tips">
        <div style="font-size:26px;font-weight:bolder">请点击屏幕右上角 [···]</div>
        <div style="margin-top:35px;font-size:20px">在 浏览器打开</div>
        <div style="margin-top:35px;font-size:24px;font-weight:bolder;color:#17abf1">一分福利购等你哦</div>
      </div>
      <div class="image">
        <img
          src="https://gjr-1252058135.cos.ap-guangzhou.myqcloud.com/0a46d7d5e366fa2971efec888ed7065.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isWeixin: false,
      isAndroid: false,
      isIOS: false
    };
  },
  created() {
    document.title = "京东包邮1分购！";
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf("micromessenger") != -1;
    this.isAndroid = ua.indexOf("Android") > -1 || ua.indexOf("Linux") > -1; //android终端
    this.isIOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isWeixin) {
      this.isWeixin = true;
    } else {
      this.isWeixin = false;
    }
  },
  methods: {
    async shop() {
      let id = this.$route.params.id || "";
      let formData = {
        material_id:
          "https://pro.m.jd.com/mall/active/3cSR8o2ks9Hd68Ro74cXvxhKVFLH/index.html",
        union_id: "2011579727",
        appkey: "ByEwACeiKsZTd7hDY",
        coupon_url: "",
        position_id: id
      };
      let { data: res } = await this.$axios({
        method: "post",
        url: "/api/index/go_turnChain",
        data: formData
      });
      if (res) {
        let params = `{"category":"jump","des":"m","url":"${res.data}","keplerID":"0","keplerFrom":"1","kepler_param":{"source":"kepler-open","channel":""},"union_open":"union_cps"}`;
        let url = `openApp.jdMobile://virtual?params=${encodeURI(params)}`;
        window.location.href = url;
        setTimeout(function() {
          let hidden =
            window.document.hidden ||
            window.document.mozHidden ||
            window.document.msHidden ||
            window.document.webkitHidden;
          if (typeof hidden == "undefined" || hidden == false) {
            window.location.href =
              "https://wqs.jd.com/downloadApp/download.html?channel=jd-msy&M_sourceFrom=index&inteneUrlParams=https://u.jd.com/pCX2VCi";
          }
        }, 2000);
      } else {
        alert("参与活动人数过多，请再次尝试~");
      }
    }
  }
};
</script>

<style lang="less" scoped>
.link {
  width: 90%;
  margin: 20px auto;
  text-align: center;
  .link_text {
    font-size: 2rem;
    text-decoration: underline;
  }
}
.image {
  width: 95%;
  margin: 20px auto;
  img {
    width: 100%;
  }
}
.tips {
  text-align: center;
  margin-top: 10vh;
}
</style>