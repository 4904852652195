import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import video from "../views/video.vue";
import videoList from "../views/video_list";
import videoDetail from "../views/video_detail";
import Plugin from "../views/plugin";
import Source from "../views/source";
import Goods from "../views/goods";
import Database from "../views/database";
import turnChain from "../views/turnChain";
import jd from "../views/jd";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/plugin",
    name: "Plugin",
    component: Plugin,
  },
  {
    path: "/database",
    name: "Database",
    component: Database,
  },
  {
    path: "/source",
    name: "Source",
    component: Source,
  },
  {
    path: "/goods",
    name: "Goods",
    component: Goods,
  }, {
    path: "/turnChain",
    name: "turnChain",
    component: turnChain,
  },
  {
    path: "/jd/:id",
    name: "jd",
    component: jd,
  },
  {
    path: "/video",
    name: "vido",
    component: video,
    children: [
      {
        path: "videolist",
        name: "videoList",
        component: videoList,
      },
      {
        path: "videodetail",
        name: "videoDetail",
        component: videoDetail,
      },
      {
        path: "/",
        redirect: { name: "videoList" },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
