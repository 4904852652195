<template>
  <div>
    <div class="title">普通二合一转链</div>
    <div class="content">
      <div class="input_div round_m">
        <span class="fl t_l">商品链接：</span>
        <div class="r_cell">
          <input
            v-model="formData.material_id"
            type="text"
            class="tool_input_text box_sizing"
            placeholder="请输入商品链接"
            name="sku"
            id="sku"
            value
          />
        </div>
      </div>
      <div class="input_div round_m">
        <span class="fl t_l">商品优惠券：</span>
        <div class="r_cell">
          <input
            type="text"
            v-model="formData.coupon_url"
            class="tool_input_text box_sizing"
            placeholder="请输入商品优惠券"
            name="sku"
            id="sku"
            value
          />
        </div>
      </div>
      <div class="input_div round_m">
        <span class="fl t_l">联盟ID：</span>
        <div class="r_cell">
          <input
            v-model="formData.union_id"
            type="text"
            class="tool_input_text box_sizing"
            placeholder="请输入京东联盟ID"
            name="sku"
            id="sku"
            value
          />
        </div>
      </div>
      <div class="input_div round_m">
        <span class="fl t_l">推广位ID：</span>
        <div class="r_cell">
          <input
            v-model="formData.position_id"
            type="text"
            class="tool_input_text box_sizing"
            placeholder="请输入京东推广位ID"
            name="sku"
            id="sku"
            value
          />
        </div>
      </div>
      <div class="input_div round_m" v-if="flag">
        <span class="fl t_l">转链结果：</span>
        <div class="r_cell">
          <input
            v-model="url"
            type="text"
            readonly="readonly"
            class="tool_input_text box_sizing"
            placeholder="转链后生成"
            name="sku"
            id="sku"
            value
          />
        </div>
      </div>
      <div class="button">
        <span class="w_120 inline_block trans" @click="go_turnChain">
          <button href="#" class="tool_btn round_m transition_a btn_blue box">
            <span class="tool_icon_s icon_public box_mr10"></span>
            <span class="text_m">转链</span>
          </button>
        </span>
        <span class="w_120 inline_block box_mw20 copy_link" data-clipboard-target="#link" >
          <a
            href="javascript:void(0);"
            class="tool_btn round_m transition_a btn_orange box tool_copy_btn"
            @click="copy" :data-clipboard-text="url"
          >
            <span class="tool_icon_s copy_icon_s icon_public box_mr10"></span>
            <span class="text_m">复制</span>
          </a>
        </span>
        <span class="w_120 inline_block" @click="clear">
          <a href="javascript:void(0);" class="tool_btn btn_border round_m transition_a box">
            <span class="tool_icon_s del_icon_s icon_public box_mr10"></span>
            <span class="text_m">清空</span>
          </a>
        </span>
      </div>
    </div>
    <div class="tips">
      怎么看链接是不是我的返利？<br/><br/>
      打开u.jd.com链接，找到链接里t_数字，这一串数字。这串数字就是你的联盟ID 就是你的返利。
    </div>
  </div>
</template>

<script>
import Clipboard from "clipboard";
export default {
  data() {
    return {
      flag: false,
      formData: {
        material_id: "",
        union_id: "",
        appkey: "ByEwACeiKsZTd7hDY",
        coupon_url: "",
        position_id: ""
      },
      url: ""
    };
  },
  created(){
    this.formData.union_id=localStorage.getItem("union_id")?localStorage.getItem("union_id"):""
    this.formData.position_id=localStorage.getItem("position_id")?localStorage.getItem("position_id"):""
  },
  methods: {
    async go_turnChain() {
      if(this.formData.position_id==""){
        alert("推广位ID不能为空");
        return false
      }
      let { data: res } = await this.$axios({
        method: "post",
        url: "/api/index/go_turnChain",
        data: this.formData
      });
      if (res.data) {
        localStorage.setItem("union_id",this.formData.union_id)
        localStorage.setItem("position_id",this.formData.position_id)
        this.flag = true;
        this.url = res.data
      }
    },
    copy(){
      if(this.url==""){
        alert("请先转链再复制！")
        return false
      }
      var clipboard = new Clipboard(".tool_copy_btn");
      clipboard.on("success", e => {
        this.$message({
          type: "success",
          message: "复制成功"
        });
        // 释放内存
        clipboard.destroy();
      });

      clipboard.on("error", e => {
        // 不支持复制
        this.$message.error("该浏览器不支持自动复制");

        // 释放内存
        clipboard.destroy();
      });
    },
    clear(){
      this.formData.material_id="";
      this.formData.union_id="";
      this.formData.coupon_url="";
      this.formData.position_id="";
      this.url=""
    }
  }
};
</script>

<style lang="less" scoped>
.title {
  text-align: center;
  margin-top: 20px;
  font-size: 26px;
}
.content {
  width: 95%;
  margin: 30px auto;
}
.input_div {
  margin-bottom: 20px;
  height: 48px;
  padding: 0 20px;
  line-height: 48px;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  font-size: 16px;
}
.round_m {
  border-radius: 10px;
}
.fl {
  float: left;
}
.r_cell {
  display: table-cell;
  width: 9999px;
}
.tool_input_text {
  height: 48px;
}
.tool_input_text,
.tool_textarea {
  font-size: 16px;
  line-height: 48px;
  padding: 0;
  width: 100%;
  vertical-align: top;
  background-color: transparent;
  border: none;
}
.button {
  margin: 30px auto;
  display: flex;
  justify-content: space-around;
}
.w_120 {
  width: 110px;
}
.inline_block {
  display: inline-block;
}
.tool_btn {
  display: block !important;
  text-align: center;
  font-size: 16px;
  width: 110px;
  height: 40px;
  line-height: 36px;
}
.btn_blue {
  background: rgb(87, 148, 255);
  background: linear-gradient(
    90deg,
    rgba(87, 148, 255, 1),
    rgba(107, 102, 255, 1)
  );
  color: #fff;
}
.icon_public {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  overflow: hidden;
  background-repeat: no-repeat;
}
.tool_icon_s {
  width: 20px;
  height: 20px;
  background-image: url(http://jingtuitui.com/static/home_v3/images/icon/btn_icon_s.png);
}
.box_mr10 {
  margin-right: 10px;
}
.text_m {
  display: inline-block;
  vertical-align: middle;
}
.btn_orange {
  background: rgb(255, 98, 68);
  background: linear-gradient(
    90deg,
    rgba(255, 96, 68, 1),
    rgba(255, 154, 47, 1)
  );
  color: #fff;
}
.copy_icon_s {
  background-position: 0 -20px;
}
.btn_border {
  box-shadow: 0 0 0 1px #e6e6e6 inset;
  color: #333;
  background-color: #fff;
}
.del_icon_s {
  background-position: 0 -40px;
}
.tips{
  width: 95%;
  margin: 30px auto;
}
</style>