import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    login_show: false,
    regist_show: false,
    myInfo_show: false,
    money_show:false,
    token:"",
    user:"",
    tab_active:"",
    aside_active:"",
  },
  mutations: {
    change_login_show(state) {
      // 变更状态
      state.login_show = !state.login_show;
      localStorage.setItem("store", JSON.stringify(state));
    },
    change_regist_show(state) {
      // 变更状态
      state.regist_show = !state.regist_show;
      localStorage.setItem("store", JSON.stringify(state));
    },
    change_token(state,value){
      // 变更状态
      state.token = value;
      localStorage.setItem("store", JSON.stringify(state));
    },
    change_user(state,value){
      // 变更状态
      state.user = value;
      localStorage.setItem("store", JSON.stringify(state));
    },
    change_tab_active(state,value){
      // 变更状态
      state.tab_active = value;
      localStorage.setItem("store", JSON.stringify(state));
    },
    change_aside_active(state,value){
      // 变更状态
      state.aside_active = value;
      localStorage.setItem("store", JSON.stringify(state));
    },
    change_myInfo_show(state){
      // 变更状态
      state.myInfo_show = !state.myInfo_show;
      localStorage.setItem("store", JSON.stringify(state));
    },
    change_money_show(state){
      // 变更状态
      state.money_show = !state.money_show;
      localStorage.setItem("store", JSON.stringify(state));
    },
  },
  actions: {},
  modules: {},
});
