<template>
  <div class="home">
    <el-header>
      <top></top>
    </el-header>
    <el-container>
      <el-aside>
        <left></left>
      </el-aside>
      <el-main>
        <div class="notice">
          <el-alert type="warning" :closable="false">
            <template slot="title">
              <div v-html="notice"></div>
            </template>
          </el-alert>
        </div>
        <div style="display:flex">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{api_content.classify.name}}</el-breadcrumb-item>
            <el-breadcrumb-item>{{api_content.title}}</el-breadcrumb-item>
          </el-breadcrumb>
          <!-- 跳转视频页 -->
          <div class="video">
            <el-button type="primary" size="mini" @click="$router.push('video')">接口对接教程</el-button>
          </div>
        </div>

        <el-card class="box-card">
          <div class="flex-box">
            <div class="text item">
              接口标题：
              <span class="title">{{api_content.title}}</span>
            </div>
            <div class="text item" style="display:flex;align-items:center">
              接口地址：
              <el-input type="textarea" autosize :value="api_content.url"></el-input>
              <el-button
                type="mini"
                class="copy_btn"
                @click="copy"
                :data-clipboard-text="api_content.url"
              >复制</el-button>
            </div>
            <div class="text item">
              是否收费：
              <span class="title">{{api_content.flag==0?'否':'是'}}</span>
            </div>
            <div class="text item">
              调用每次收费金币：
              <span class="title">{{api_content.money}}</span>
            </div>
          </div>
        </el-card>
        <el-card class="box-card">
          <div class="box-card-content">
            <div class="text item" style="text-align:start">接口文档：</div>
            <div v-dragscroll class="bcc-info" v-html="api_content.content"></div>
          </div>
          <!-- 登录界面 -->
          <login></login>
          <!-- 注册界面 -->
          <regist></regist>
          <!-- 我的信息页面 -->
          <myInfo :source="source"></myInfo>
        </el-card>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import top from "../components/header";
import left from "../components/aside";
import login from "../components/login";
import regist from "../components/regist";
import myInfo from "../components/myInfo";
import Clipboard from "clipboard";
export default {
  data() {
    return {
      notice: null,
      source:[],
      api_content: {
        classify: {
          name: null
        },
        title: null,
        url: null,
        flag: 0,
        money: null,
        content: null
      }
    };
  },
  created() {
    if (this.$store.state.aside_active != "") {
      this.getApiContent(this.$store.state.aside_active);
    }
    this.get_notice();
    this.get_source();
  },
  computed: {
    aside_active() {
      return this.$store.state.aside_active;
    },
    user() {
      return this.$store.state.user;
    }
  },
  watch: {
    aside_active(newVal, oldVal) {
      this.getApiContent(newVal);
    },
    user(newVal, oldVal) {
      this.getApiContent(this.$store.state.aside_active);
    }
  },
  methods: {
    // 获取消息源
    async get_source(){
      let {data:res} = await this.$axios({
        methods:"get",
        url:"api/index/get_source",
      })
      this.source = res.data
    },
    async getApiContent(id) {
      let { data: res } = await this.$axios({
        method: "post",
        url: "/api/index/get_api_content",
        data: {
          api_id: id
        }
      });
      if (!res.data) {
        this.api_content = {
          classify: {
            name: null
          },
          title: null,
          url: null,
          flag: 0,
          money: null,
          content: null
        };
      } else {
        this.api_content = res.data;
        if (this.$store.state.user != "") {
          this.api_content.url = this.api_content.url.replace(
            "你的appkey",
            this.$store.state.user.appkey
          );
          this.api_content.content = this.api_content.content.replace(
            /你的appkey/g,
            this.$store.state.user.appkey
          );
          console.log(this.api_content.content);
        }
      }
    },
    copy() {
      var clipboard = new Clipboard(".copy_btn");
      clipboard.on("success", e => {
        this.$message({
          type: "success",
          message: "复制成功"
        });
        // 释放内存
        clipboard.destroy();
      });

      clipboard.on("error", e => {
        // 不支持复制
        this.$message.error("该浏览器不支持自动复制");

        // 释放内存
        clipboard.destroy();
      });
    },
    async get_notice() {
      let { data: res } = await this.$axios({
        method: "post",
        url: "/api/index/get_notice"
      });
      if (res.code == 1) {
        this.notice = res.data.content;
      }
    }
  },
  components: {
    top,
    left,
    login,
    regist,
    myInfo
  }
};
</script>


<style lang="less">
/* 滚动条样式 */
::-webkit-scrollbar {
  width: 12px;
}
/* 滚动条的滑块 */
::-webkit-scrollbar-thumb {
  background-color: #eaecf1;
  border-radius: 3px;
}
.home {
  .el-textarea {
    width: 450px !important;
  }
  .el-textarea__inner {
    color: #e6a23c !important;
    font-weight: bold;
  }
  .el-header {
    padding: 0 10px;
  }
}
</style>

<style lang="less" scoped>
el-input {
  width: 200px !important;
}
.video {
  position: relative;
  top:-6px;
  left: 20px;
}
.text {
  white-space: nowrap;
  font-size: 14px;
  color: #909399;
}

.title {
  font-weight: 600;
  color: #e6a23c;
}

.notice {
  margin-bottom: 20px;
}
.item {
  width: 50%;
  padding: 18px 0;
  text-align: center;
}

.copy_btn {
  cursor: pointer !important;
  margin-left: 10px;
}

.flex-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.box-card {
  margin-top: 25px;
  .bcc-info {
    cursor: pointer;
    overflow-x: scroll;
    font-size: 14px;
    color: #909399;
    font-weight: 600;
  }
}
</style>