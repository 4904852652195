<template>
  <!-- 登录界面 -->
  <el-dialog
    title="登录"
    :visible.sync="login_show"
    width="30%"
    :close-on-click-modal="false "
    @close="dialog_close()"
  >
    <el-form :model="form" :rules="rules" ref="form">
      <el-form-item label="用户名：" prop="username">
        <el-input v-model="form.username" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="密码：" prop="password">
        <el-input type="password" v-model="form.password" autocomplete="off"></el-input>
      </el-form-item>
      <el-link target="_blank" @click="regist">没有账号，点击此处进行注册</el-link>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$store.commit('change_login_show')">取 消</el-button>
      <el-button type="primary" @click="login('form')">登 录</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      form: {
        username: "",
        password: ""
      },
      rules: {
        username: [
          { required: true, message: "用户名不能为空", trigger: "blur" }
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          { min: 6, message: "长度最少6个字符", trigger: "blur" }
        ]
      }
    };
  },
  computed: {
    login_show: {
      get() {
        return this.$store.state.login_show;
      },

      set(v) {
        this.$store.commit("change_login_show");
      }
    }
  },
  methods: {
    regist() {
      this.$store.commit("change_login_show");
      this.$store.commit("change_regist_show");
    },
    login(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          let { data: res } = await this.$axios({
            method: "post",
            url: "/api/index/login",
            data: {
              form: this.form
            }
          });
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "success"
            });
            this.$store.commit("change_token", res.data);
            this.form.username = "";
            this.form.password = "";
            this.$store.commit("change_login_show");
          } else {
            this.$message.error(res.msg);
          }
        } else {
          console.log(valid);
          return false;
        }
      });
    },
    dialog_close() {
      this.$refs["form"].resetFields();
    }
  }
};
</script>